import { Modal } from 'antd';
import style from './index.module.less';
import { CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
interface Iprops {
  isModalOpen: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  children: ReactElement;
  width?: number;
  closeIcon?: boolean;
  isNoAddAccount?: boolean;
  isMask?: boolean;
  isCenter?: boolean;
  errIconOut?: boolean;
  zIndex?: number;
}

const Index: React.FC<Iprops> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  width,
  closeIcon = true,
  isNoAddAccount = true,
  isMask = true,
  isCenter = false,
  errIconOut = false,
  zIndex = 999,
}) => {
  const { isMobile } = useConfigProvider();

  useEffect(() => {
    if (isModalOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [isModalOpen]);
  return (
    <Modal
      title="Basic Modal"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={width}
      closable={false}
      mask={isMask}
      centered={isCenter}
      transitionName=""
      zIndex={zIndex}
    >
      {/* maskTransitionName="" */}
      <div className={style.container} style={{ padding: isNoAddAccount ? '10px' : '0px' }}>
        {closeIcon && errIconOut && isMobile ? (
          <div
            className={classNames({
              [style.outClose]: true,
              [style.close]: true,
            })}
            onClick={handleCancel}
          >
            <CloseCircleOutlined style={{ color: '#ffffff', fontSize: '20px' }} />
          </div>
        ) : (
          <div
            className={classNames({
              [style.close]: true,
            })}
            onClick={handleCancel}
          >
            <CloseOutlined />
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default Index;
