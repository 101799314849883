import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Image from 'next/image';
import { useUserProvider } from '@/context/UserProvider';
import { message } from 'antd';
import AddLinkModal from '@/components/leaksFound/AddLinkModal/index';
import { useTranslation } from 'next-i18next';
import { SettingOutlined } from '@ant-design/icons';
import dynamic from 'next/dynamic';
const UnlockModal = dynamic(() => import('@/components/UnlockModal/index'), {
  ssr: false,
});
const Index: React.FC<any> = () => {
  const { t } = useTranslation('common');
  const { userInfo, onSignOut, userSub } = useUserProvider();

  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);

  const router = useRouter();
  const menuList = [
    {
      url: '/dashboard',
      name: t('Dashboard'),
    },
    {
      url: '/leaks-found',
      name: t('LeaksFound'),
      isLock: true,
    },
    {
      url: '/social-media',
      name: t('Social Media'),
      isLock: true,
    },
    {
      url: '/safe-links',
      name: t('Safelinks'),
    },
    {
      url: '/protected-Accounts',
      name: t('ProtectedAccounts'),
    },
    {
      url: '/setting',
      name: t('Subscription'),
    },
  ];
  // 跳转弹窗
  const handleOpneAddLinkModal = (e: any) => {
    e.stopPropagation();
    setIsOpenLinkModal(true);
  };
  const [isUnlockModal, setIsUnlockModal] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.head} onClick={() => router.push('/')}>
        {/* <Image
          src={imgUrl("/images/layouts/erasa.png", "mid")}
          height={50}
          width={200}
          alt="erasa"
        ></Image> */}
        <img src={'/images/layouts/erasa.webp'} style={{ height: '50px', width: '180px' }} alt="erasa" />
      </div>
      <div className={style.line}></div>
      <ul className={style.tabList}>
        {menuList.map((item, index) => (
          <li
            className={classNames(style.tabItem, {
              [style.actice]: item.url == router.pathname,
            })}
            key={index}
            onClick={() => {
              if (!item.isLock || userSub?.item_id) {
                router.push(item.url);
              }
            }}
          >
            <Image
              src={`/Images/dashboard/df/${index}-${item.url == router.pathname ? '1' : '0'}.svg`}
              height={20}
              width={20}
              alt=""
              className={style.icon}
            ></Image>
            <span className={style.name}>{item.name}</span>
            {item.isLock && !userSub?.item_id && (
              // <span className={style.add} onClick={handleOpneAddLinkModal}>
              //   +
              // </span>
              <div
                className={style.lock}
                onClick={(e) => {
                  setIsUnlockModal(true);
                }}
              >
                <img src="/images/dashboard/pink-lock.webp" alt="lock" />
                <span>UNLOCK</span>
              </div>
            )}
          </li>
        ))}
      </ul>
      <div className={style.bottom}>
        <div>
          <div className={style.uif}>
            {userInfo?.avatar ? (
              <div>
                <img src={userInfo?.avatar} alt="avatar" />
              </div>
            ) : (
              <div>{userInfo?.avatar ? userInfo?.first_name : 'E'}</div>
            )}
            <div>
              <div>
                {userInfo?.first_name
                  ? userInfo?.first_name + (userInfo?.last_name ? userInfo?.last_name : '')
                  : userInfo?.email?.split('@')[0]}
              </div>
              <div>{userInfo?.email}</div>
            </div>
          </div>
          <div className={style.action}>
            {/*正常 */}
            {(userSub?.status == 'normal' || userSub?.status == 'active') && (
              <div className={style.ac1}>
                <div>
                  <img src="/images/dashboard/df/vip.webp" alt="" />
                  <span>{userSub?.name}</span>
                </div>
                <div onClick={() => router.push('/setting')}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )}
            {/* 过期 */}
            {/* {userSub?.status == "canceled" && (
              <div className={style.ac2}>
                <div>
                  <img src="/images/dashboard/df/error.webp" alt="" />
                  <span>Deactivated</span>
                </div>
                <div onClick={() => router.push("/setting")}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )} */}
            {userSub?.status == 'expire' && (
              <div className={style.ac3}>
                <div>
                  <img src="/images/dashboard/df/errVip.webp" alt="" />
                  <span>Expired</span>
                </div>
                <div>Renew</div>
              </div>
            )}
            {!userSub?.item_id && (
              <div className={style.ac1}>
                <div>
                  <img src="/images/dashboard/df/vip.webp" alt="" />
                  <span>{userSub?.name}</span>
                  <span>Free trial</span>
                </div>
                <div onClick={() => router.push('/setting')}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <div onClick={() => router.push('/')} style={{ paddingTop: '1px' }}>
              <Image src={'/images/dashboard/home.svg'} height={20} width={20} alt="home" />
            </div>
            <div onClick={() => router.push('/profile')} style={{ paddingTop: '1px' }}>
              <SettingOutlined
                style={{
                  color: '#3A4D65',
                }}
              />
            </div>
          </div>
          <div
            onClick={() => {
              onSignOut();
              message.success(t('logout success'));
              router.push('/');
            }}
            style={{ paddingTop: '1px' }}
          >
            <Image src={'/images/dashboard/back.svg'} height={20} width={20} alt="home" />
          </div>
        </div>
      </div>
      {/* <AddLinkModal
        isAddLinkModal={isOpenLinkModal}
        handleClose={() => {
          setIsOpenLinkModal(false);
        }}
        upDataList={() => {}}
      ></AddLinkModal> */}
      <UnlockModal isModalOpen={isUnlockModal} handleCancel={() => setIsUnlockModal(false)}></UnlockModal>
    </div>
  );
};

export default Index;
