import { useState, useEffect } from 'react';

const ScrollListener = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset || document.documentElement.scrollTop;
    setScrollPosition(position);
  };

  useEffect(() => {
    // 监听滚动事件
    window.addEventListener('scroll', handleScroll);
    // 清理函数，组件卸载时移除监听
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition;
};

export default ScrollListener;
